<template>
        <iframe style="width:100%;height:100vh" :src="url"></iframe>
</template>

<script>
    export default {
        data() {
            return {
                url: this.$route.query.url
            }
        },
    }
</script>

<style lang="less" scoped>

</style>