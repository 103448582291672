<template>
  <div class="bodyBox">
    <div class="title">提交订单</div>
    <div class="flex_box">
      <div style="
          width: 4rem;
          height: 4rem;
          border-radius: 0.2rem;
          overflow: hidden;
          margin-left: 0.35rem;
        ">
        <img :src="detailsData.cover_pic" alt="" style="width: 100%; height: 100%" /><img :src="detailsData.cover_pic"
          alt="" style="width: 100%; height: 100%" />
      </div>

      <div class="nameFlex">
        <div style="width: 100%">
          <div class="textOne">{{ detailsData.name }}</div>
          <div class="color_red">￥{{ detailsData.price }}</div>
        </div>
      </div>
    </div>
    <div>
      <van-form>
        <van-cell-group inset>
          <van-field v-model="phone" type="tel" style="zoom: none !important" name="电话号码" label="电话号码"
            placeholder="电话号码" maxlength="11" :rules="[{ required: true, message: '请填写电话号码' }]" />
          <van-field v-model="username" name="填写姓名" style="zoom: none !important" label="填写姓名" placeholder="填写姓名"
            :rules="[{ required: true, message: '请填写您的姓名' }]" />
          <van-field v-model="result" is-link style="zoom: none !important" readonly name="picker" label="所属城市"
            placeholder="点击选择城市" @click="showPicker = true" />
          <van-field v-model="addrss_detail" name="详细地址" style="zoom: none !important" label="详细地址" placeholder="详细地址"
            :rules="[{ required: true, message: '请填写详细地址' }]" />

          <div style="
              font-size: 0.3rem;
              color: red;
              margin: var(--van-cell-group-inset-padding);
            ">
            注意:提交订单信息支付完成后，自动提货，系统将在2天内完成发货，填写地址错误可能导致发货不准确，请在本周内注意查看手机短信，支付完成后会发送短信通知您
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" :columns-field-names="{ text: 'label' }" @confirm="onConfirm"
        @cancel="showPicker = false" />
    </van-popup>
    <div class="button">
      <div class="btn" @click="getPayment">确认支付</div>
    </div>
    <div style="padding: 0 0.24rem" class="radio" v-if="!showFooter">
      <van-radio-group v-model="checked">
        <van-radio checked-color="#e74f3f" name="1">提交即视为您已阅读并同意<span style="color: #e74f3f"
            @click="$router.push('/userAgreement')">《用户协议》</span>和<span style="color: #e74f3f"
            @click="$router.push('/privacyPage')">《隐私协议》</span></van-radio>
      </van-radio-group>
    </div>
    <div v-if="ifarmeUrl" style="heigth:100%"></div>
  </div>
</template>

<script>
import axios from "axios";
import { showToast } from "vant";
export default {
  data() {
    return {
      price_view: "",
      box_id: "",
      detailsData: {},
      username: "",
      result: "",
      addrss_detail: "",
      showPicker: false,
      goods_id: "",
      channel_id: "",
      pay_price: "0",
      phone: "",
      pay_type: "",
      pay_mode: "",
      adv_m_id: "",
      adv_client_id: "",
      adv_client_appid: "",
      channel_no: "",
      coupon_id: "",
      payIcon: "",
      buttonChange: false,
      platfrom: "",
      openid: "",
      subTrue: true,
      order_url_c: "",
      checked: "1",
      logidUrl: "",
      showFooter: "",
    };
  },
  created() {
    this.goods_id = this.getQueryString("goods_id");
    this.payIcon = this.getQueryString("payIcon");
    this.channel_id = this.getQueryString("channel_id");
    this.pay_price = this.getQueryString("pay_price");
    this.phone = "";
    this.pay_type = this.getQueryString("pay_type");
    this.pay_mode = this.getQueryString("pay_mode");
    this.logidUrl = this.getQueryString("logidUrl");
    this.showFooter = this.getQueryString("showFooter");
    this.adv_m_id =
      this.getQueryString("adv_m_id") == "undefined" || !this.getQueryString("adv_m_id")
        ? ""
        : this.getQueryString("adv_m_id");
    if (
      this.getQueryString("adv_m_id") == "undefined" ||
      !this.getQueryString("adv_m_id")
    ) {
    } else {
      sessionStorage.setItem("adv_m_id", this.getQueryString("adv_m_id"));
    }

    this.adv_client_id = this.getQueryString("adv_client_id");
    this.adv_client_appid = this.getQueryString("adv_client_appid");
    this.callback_url = this.getQueryString("callback_url");
    this.channel_no = this.getQueryString("channel_no");
    this.coupon_id = this.getQueryString("coupon_id");
    this.platfrom = this.getQueryString("platfrom");
    this.payVideo = this.getQueryString("payVideo");
    this.box_id = this.getQueryString("goods_id");
    this.getBoxList();
    this.getCityList();
  },
  mounted() {
    //   window.addEventListener('pageshow', function (e) {
    //     if(e.persisted || (window.performance && window.performance.navigation.type == 2)){
    //             window.location.reload()
    //     }
    // });
    document.addEventListener("visibilitychange", () => {
      // console.log(504)
      if (
        this.buttonChange &&
        (this.getQueryString("return_url") || this.order_url_c) &&
        !document.hidden
      ) {
        this.buttonChange = false;
        if (this.getQueryString("return_url")) {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
        if (this.isBaiDu()) {
          // this.iframe_rul=''
          window.location.href = unescape(this.order_url_c);
        } else {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
      }
    });
  },
  methods: {
    isBaiDu() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (sUserAgent.indexOf("baidu") > 0) {
        return true;
      }
      return false;
    },
    updateQueryStringParameter(uri, key, value) {
      if (!value) {
        return uri;
      }
      uri = uri.split("#/details").join("");
      var re = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.indexOf(key) !== -1) {
        return uri + "#/details";
      }
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2") + "#/details";
      } else {
        return uri + separator + key + "=" + value + "#/details";
      }
    },
    getCityList() {
      axios.get("/api/v1/default/districtList").then((res) => {
        console.log(res.data.data);
        this.columns = res.data.data;
      });
    },
    onConfirm(e) {
      this.result = "";
      this.resetObj();
      e.selectedOptions.forEach((element, index) => {
        if (index == e.selectedOptions.length - 1) {
          this.result += element.label;
        } else {
          this.result += element.label + "-";
        }
      });
      this.tihuoObj = {
        province: e.selectedOptions[0].label,
        province_id: e.selectedOptions[0].value,
        city: e.selectedOptions[1].label,
        city_id: e.selectedOptions[1].value,
        district: e.selectedOptions[2].label,
        district_id: e.selectedOptions[2].value,
      };
      this.showPicker = false;
      console.log(e);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return "";
    },
    phoneOs() {
      var u = navigator.userAgent,
        isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        return "Android";
      }

      if (isiOS) {
        return "IOS";
      }
      return "";
    },
    resetObj() {
      this.tihuoObj = {
        province: "",
        province_id: "",
        city: "",
        city_id: "",
        district: "",
        district_id: "",
      };
    },
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    getBoxList() {
      axios
        .get(`${window.location.origin}/api/v1/box/index?box_id=${this.box_id}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.price_view = res.data.data.box.price;
            this.detailsData = res.data.data.box;
          }
        });
      axios
        .post(`${window.location.origin}/api/v1/channel/getPayChannel`, {
          equipment_client: this.phoneOs() == "IOS" ? 3 : 2,
          pay_client: 3, //3: h5  2:xiaochengxu  1:app
          channel_no: this.getQueryString("channel_no"),
        })
        .then((res) => {
          this.payIcon = res.data.data.pay_way;
        });
    },
    getPayment() {
      if (!this.phone) {
        return showToast("请输入手机号");
      }
      const rep = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!rep.test(this.phone)) {
        return showToast("请输入正确的手机号");
      }
      if (!this.username) {
        showToast("请输入您的姓名");
        return false;
      }
      if (!this.tihuoObj.province) {
        showToast("请选择省市区");
        return false;
      }
      if (!this.addrss_detail) {
        showToast("请输入详细地址");
        return false;
      }
      let that = this;
      if (!this.subTrue) {
        return;
      }
      if (this.subTrue) {
        this.subTrue = false;
      }
      const {
        pay_price,
        phone,
        pay_type,
        goods_id,
        pay_mode,
        adv_m_id,
        adv_client_id,
        channel_id,
        callback_url,
        channel_no,
        coupon_id,
        payIcon,
        platfrom,
        openid,
      } = this;
      axios
        .post(`${window.location.origin}/api/v1/channel/getPayChannel`, {
          equipment_client: this.phoneOs() == "IOS" ? 3 : 2,
          pay_client: 3, //3: h5  2:xiaochengxu  1:app
          channel_no: channel_no,
        })
        .then((res) => {
          let o_chanId = res.data.data
          let return_url_succ = "";
          if (that.logidUrl) {
            return_url_succ =
              window.location.origin +
              `/#/sud?logidUrl=${encodeURIComponent(this.logidUrl)}`;
          } else {
            return_url_succ = window.location.origin + "/#/sud";
          }
          axios
            .post(`${window.location.origin}/api/v1/tui/orderDeliverSubmit`, {
              pay_price,
              phone,
              pay_type: res.data.data.pay_way,
              channel_id: res.data.data.channel_id,
              coupon_id,
              goods_id,
              callback_url:
                callback_url +
                `/?channel_no=${channel_no}#/`,
              pay_mode,
              goods_name: "手机",
              return_url: return_url_succ,
              adv_m_id,
              adv_client_id,
              ...this.tihuoObj,
              username: this.username,
              detail: this.addrss_detail,
            })
            .then((res) => {
              console.log(res)

              let order_url = escape(res.data.data.return_url);
              that.order_url_c = escape(res.data.data.return_url);
              if (res.data.code == 0) {
                this.subTrue = true;
                if (platfrom) {
                  //证明用户点击过后才进入后台
                  that.buttonChange = true;
                  // that.returnUrl=res.data.data.return_url
                  let gourl = "";
                  if (window.location.href.indexOf("return_url") > -1) {
                    gourl =
                      window.location.href.split("return_url")[0] +
                      "#/" +
                      window.location.href.split("return_url")[1].split("#/")[1];
                  }
                  let newurl = that.updateQueryStringParameter(
                    gourl ? gourl : window.location.href,
                    "return_url",
                    order_url
                  );
                  window.history.replaceState(
                    {
                      path: newurl,
                    },
                    "",
                    newurl
                  );
                }
                // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
                if (platfrom == "13") {
                  let url = res.data.data.pay_data.split("//");
                  // url.splice(4,0,'convertPayway')
                  let src = url[1].split("/");
                  src.splice(3, 0, "convertPayway");
                  let sure = src.join("/");
                  url = url[0] + "//" + sure;
                  axios
                    .post(url, {
                      wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                      payDataType: "",
                    })
                    .then((bizData) => {
                      if (bizData.data.data.payDataType == "payurl") {
                        if (that.payIcon == 2) {
                          window.location.href = bizData.data.data.payData;
                        } else {
                          var m = bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                          if (m && m[1]) {
                            let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                            window.location.href = mockUrlScheme;
                          }
                        }
                      }
                    });
                } else {
                  if (o_chanId.pay_way == 2) {
                    if (o_chanId.platfrom == 18) {
                      let qrcodeUrl = res.data.data.pay_data.split('qrContent=')[1].split('&')[0]
                      let url = 'alipays://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=' + encodeURIComponent(qrcodeUrl)
                      window.location.href = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(url)
                    }
                    else if (o_chanId.platfrom == 21 && that.isBaiDu()) {
                      if (that.phoneOs() && that.phoneOs() == 'Android') {
                        window.location.href = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(res.data.data.pay_data)

                      } else if (that.phoneOs() && that.phoneOs() == 'IOS') {
                        window.location.href = 'https://render.alipay.com/p/s/i/?scheme=' + encodeURIComponent(res.data.data.pay_data)

                      } else {
                        window.location.href = res.data.data.pay_data
                      }
                    }
                    else if (o_chanId.platfrom == 30) {

                        // url = 'alipays://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=' + encodeURIComponent(res.data.data.pay_data)
                        let url = 'alipays://platformapi/startapp?appId=20000067&url=' + encodeURIComponent(res.data.data.pay_data)
                        // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
                        if ( that.isBaiDu()) {
                          if (that.phoneOs() && that.phoneOs() == 'Android') {
                            url = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(url)

                          }
                          if (that.phoneOs() && that.phoneOs() == 'IOS') {
                            url = 'https://render.alipay.com/p/s/i/?scheme=' + encodeURIComponent(url)

                          }

                        }
                        window.location.href=url

                    }
                    else {
                      window.location.href = res.data.data.pay_data

                    }


                  } else {
                    if (platfrom == '21') {
                      var m = res.data.data.pay_data.match(/wxaurl\.cn\/(\w+)/)
                      if (m && m[1]) {
                        let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`
                        window.location.href = mockUrlScheme
                      }
                    }
                    else {
                      window.location.href = res.data.data.pay_data

                    }

                  }
                }
              } else {
                console.log(res);
                showToast(res.data.msg);
                this.subTrue = true;
                //   setTimeout(() => {
                //     this.back();
                //   }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              showToast(err);
              this.subTrue = true;
              setTimeout(() => {
                this.back();
              }, 3000);
            });
        });
    },
  },
};
</script>

<style lang="less" scoped>
input {
  zoom: none !important;
}

.bodyBox {
  font-size: 0.35rem;
}

.title {
  font-size: 0.5rem;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
}

.flex_box {
  display: flex;
  align-items: center;
}

.nameFlex {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
}

.color_red {
  margin-top: 0.2rem;
  color: red;
  font-size: 0.4rem;
}

.textOne {
  width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  padding: 0 0.6rem;
  margin-top: 1.29rem;

  .btn {
    width: 100%;
    height: 1.17rem;
    background: linear-gradient(90deg, #ff3232 0%, #fa4c4c 100%);
    border-radius: 0.89rem;
    font-size: 0.43rem;
    font-weight: 400;
    color: #fefefe;
    text-align: center;
    line-height: 1.17rem;
  }
}

.radio {
  margin-top: 0.2rem;
  font-size: 0.34rem;
}
</style>
